<template>
  <div class="settings content pos-r z1 pt-20 pb-20">
    <div class="box">
      <h1 class="title mb-20 mt-20 flex jc-c">Настройки</h1>
      <TabSwipeLinks class="jc-c" :items="tabs" />

      <main class="settings__content m-auto pos-r pl-20 pr-20">
        <BaseLoad v-if="isLoad" class="pos-a grid jc-c z99999 white-transparent" />
        <RouterView @loadAction="isLoad = $event" />
      </main>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { BaseLoad, TabSwipeLinks } from '@/components'

// Data
const store = useStore()
const isLoad = ref(false)
const tabsList = [
  {
    any: true,
    text: 'Уведомления',
    link: '/settings',
    append: false
  },
  {
    any: false,
    text: 'Компании',
    link: '/settings/companies',
    append: false
  }
]

// Computed
const profile = computed(() => store.getters.profile)
const isHeadFinancier = computed(() => store.getters['isHeadFinancier'])
const tabs = computed(() =>
  isHeadFinancier.value || profile.value?.id === 1510 ? tabsList : tabsList.filter((el) => el.any)
)
</script>

<style lang="scss" scoped>
.settings {
  overflow: auto;
  min-height: calc(100vh - 300px);

  &__content {
    width: 740px;
    min-height: 50vh;
  }
}
</style>
